import React, { useState } from "react";
import axios from "axios";
import {
  CButton,
  CCard,
  CCardBody,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CToast,
  CToastBody,
  CToastHeader,
  CImage,
  CFormLabel,
} from "@coreui/react";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import "../scss/style_guide.css";
import "./Login.css";
import img_logo_kite from "../assets/images/img_logo_kite.png";
import img_algorithm_trading from "../assets/images/img_algorithm_trading.svg";
import { API_URL } from "../utils/API";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ email: "", password: "", general: "" });
  const [validated, setValidated] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toast, setToast] = useState(false);
  const [kiteLoading, setKiteLoading] = useState(false); // New state for loading

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);

    try {
      const response = await axios.post(`${API_URL}/login`, {
        email,
        password,
      });
      if (response.status === 200) {
        setToastMessage("Login successful! Redirecting...");
        setToast(true);
        setTimeout(() => {
          navigate("/dashboard"); // Adjust the path as necessary
        }, 2000);
      } else {
        setError({ ...error, general: "Invalid credentials." });
      }
    } catch (error) {
      setError({
        ...error,
        general: "An error occurred. Please try again later.",
      });
    }
  };

  const handleLoginKite = async (e) => {
    e.preventDefault();
    setKiteLoading(true); // Set loading state to true

    try {
      const response = await axios.get(`${API_URL}/login`);
      if (response.status === 200) {
        setTimeout(() => {
          window.open(response.data);
          setKiteLoading(false); // Set loading state to false after opening
        }, 3000);
      } else {
        setError({ ...error, general: "Invalid credentials." });
        setKiteLoading(false); // Set loading state to false
      }
    } catch (error) {
      setError({
        ...error,
        general: "An error occurred. Please try again later.",
      });
      setKiteLoading(false); // Set loading state to false
    }
  };

  return (
    <>
      <div className='login-container'>
        <div className='home-login'>
          <div className='home-outer'>
            <div className='home-inner-left'>
              <div className='img_algorithm_trading'>
                <CImage src={img_algorithm_trading}></CImage>
              </div>
              <h1>
                Build Wealth <span>Automatically</span> With Stocks
              </h1>
            </div>
            <div className='home-inner-form'>
              <div className='img_logo_kite'>
                <CImage src={img_logo_kite}></CImage>
                <h2>Log in With Kite</h2>
              </div>
              <CForm noValidate validated={validated} onSubmit={handleSubmit}>
                <CFormLabel>User ID</CFormLabel>
                <CInputGroup>
                  <CFormInput
                    type='email'
                    id='validationCustomEmail'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Email address'
                    required
                    className='form-input'
                  />
                </CInputGroup>
                <CFormLabel>Password</CFormLabel>
                <CInputGroup>
                  <CFormInput
                    type='password'
                    id='validationCustomPassword'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder='Password'
                    required
                    className='form-input'
                  />
                </CInputGroup>
                {error.general && <p className='error-text'>{error.general}</p>}
                <CButton
                  type='submit'
                  className='login-button login-button-border'>
                  Login
                </CButton>
                <div className='sepration-line'>
                  <span>OR</span>
                </div>
                <CButton className='login-button' onClick={handleLoginKite}>
                  Log in With Kite
                </CButton>
                {/* <div className="form-external-link">
                  <a href="javascript:void(0)">Forget your ID or password?</a>
                </div> */}
              </CForm>
            </div>
          </div>
        </div>
      </div>
      {kiteLoading && (
        <div className='loader-overlay'>
          <ThreeDots
            visible={true}
            height='80'
            width='80'
            color='#ff5722'
            radius='9'
            ariaLabel='three-dots-loading'
          />
        </div>
      )}
    </>
  );
};

export default Login;
